<template>
  <div class="home" style="height: 100%">
    <v-container style="height: 70%" align-center justify-center>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><strong>{{ $t("comments.title") }}</strong></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6">{{ $t("comments.subtitle") }}</v-col>
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6">
          <v-textarea v-model="comment" :label="$t('comments.label')" />
        </v-col>
        <v-col cols="1">
          <v-btn
            style="height: 100%"
            @click="
              editComment = !editComment;
              input = comment;
              commitComment();
            "
            ><v-icon v-if="!editComment">mdi-pencil</v-icon
            ><v-icon v-else>mdi-check</v-icon></v-btn
          >
        </v-col>
        <v-col cols="2" />
      </v-row>
    </v-container>
    <v-footer
      class="justify-center"
      style="position: absolute; bottom: 50px; width: 100%"
    >
      <SimpleKeyboard
        v-show="editComment"
        :input="input"
        @onChange="onChange"
      />
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";
import SimpleKeyboard from "@/components/SimpleKeyboard";
export default {
  name: "Comments",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      editComment: false,
      comment: "",
      input: "",
    };
  },
  mounted() {
    if (this.$store.state.apptId == 0) this.$router.push("/");
    this.validate();
  },
  methods: {
    onChange(input) {
      if (this.editComment) {
        this.comment = input;
        let cb_conf = this.$store.state.callback;
        if (this.$store.state.hasMotors)
          axios.get("http://localhost:3000/front");
        else {
          if (this.$store.state.timeout !== "not yet")
            window.clearTimeout(this.$store.state.timeout);
          let timeout = window.setTimeout(() => {
            window.location.href = cb_conf;
          }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
          this.$store.commit({ type: "timeout", timeout: timeout });
        }
      }
    },
    onInputChange(input) {
      this.input = input.target.value;
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
    },
    commitComment() {
      if (!this.editComment) {
        this.$store.commit("customerComment", { comment: this.comment });
      }
      this.validate();
    },
    validate() {
      if (!this.editComment) {
        this.$store.commit("enableNext");
      } else {
        this.$store.commit("disableNext");
      }
    },
  },
};
</script>
